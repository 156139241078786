.account-setting{
    .card{
        box-shadow: none;
        border: 1px solid #e5e5e5;
    }
    .form-control.is-invalid{
        background-image: none;
    }
    .form-group{
        ul{
            li{
                img{
                    width: 15px;
                }
            }
        }
    }

    .top-container{
        background-color: white;
        border-radius: 4px;
        .ux-designer{
            color: white;
            padding-top: 1.5em;
            padding-left: 6em;
            background: linear-gradient(270.31deg, #98D9BB 0.51%, rgba(255, 255, 255, 0) 68.45%), #447E63;
            height: 70px;
            font-size: 20px;
            border-top-left-radius:4px;
            border-top-right-radius:4px;
        }
        .shift-image{
            margin-top: -40px;
        }
        .company-info{
            height: 60px;
            margin-top: -1.5em;
            margin-left: 3em;
        }
        .green-status-text{
            padding: 5px 10px;
            background-color: $light-green;
            color:$white;
            font-size: 11px;
            font-weight: 200;
            border-radius: 15px;
        }
    }
}