
.page-content.pro-padding{ padding-bottom: 131px !important;}
.profile-page .nav-tabs{
    
    .nav-item {
        & .active{
            background-color: $dark-green;
            border:none;
            padding-top: 7px;
            padding-bottom: 7px;
            color:$white;
            &.nav-link{
                color: $white;
            }
        }
        & .nav-link{
            color:$dark-green;
            border: none;
            &:hover{
                border: none;
            }
        }
        margin-bottom: 0px;
        border-bottom: 1px solid $dark-green;
        height:33px;
    }
    text-align: left;
    border: 1px solid #eeee;
    background-color: $white;
    padding:10px;
}

.profile-page {
    & .profile-row{  
        background-color: $white;
        padding: 40px 0px;
        border-bottom: 1px solid #D8D8D8;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    }

    .profile-header{
        position: absolute;
        left: 24%;
        top: 65%;
    }
    .profile-user-logo{
        min-height: 72px;
        position: relative;
        font-size: 30px;
        z-index: 1;
        overflow: hidden;
        text-align: center;
        & img{
            position: relative;
            left: -33%;
        }
        & .right-pencil-section{
            position: absolute;
            right: 122px;
            z-index: 2;
            top: 13px;
            background-color: #fff;
            & i{
                border-radius: 100%;
                padding: 5px;
                border: 1px solid #eeee;
            }
        }
    }
    .profile-user-logo:before, .profile-user-logo:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 60%;
        height: 1px;
        content: '\a0';
        background-color:#eeee;
    }

    .profile-user-logo:before {
        margin-left: -50%;
        text-align: right;
    }
    .color {
        background-color: #ccc;
    }
}
//Edit Profile Modal
.edit-profile-modal{

    .upload-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & .upload-link{
            text-decoration-line: underline;
        }
    }
    .dropzone{
        width: auto;
        min-height: auto;
        border: none;
        padding-left: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        & .dz-message{
            padding :0px;
            font-size: 16px;
            width:auto;
            text-align: left;
        }   
        img{
            width: 15px;
        }
    }
    .profile-logo-container{
        border-radius: 100%;
        border: 1px solid #eeee;
        padding: 5px;
        & img{
            background-color: $white;
        }
    }
}
.resume-upload-modal, .candidature-upload-modal{
    & .upload-file{
        box-shadow: none;
        border-radius: 5px;
        & .card-body{
            padding: 0px;
        }
       & .dz-message{
           text-align: center;
       }
    }
    & .form-group input::placeholder{
        font-size:12px;
    }
}

//Table Css

.profile-page .tab-content{

    & .tab-pane{
        padding: 25px;
        background-color: #fff;
        margin-top: 20px;
        border: 1px solid #eeee;

        &.about-page{
            min-height: 300px;
        }
        &.educational-tab, &.employment-tab, &.courses-tab, &.certificate-tab{
            & i.mdi-delete-outline, & i.bx-pencil{
                font-size: 18px;
                cursor: pointer;
            }
            & tbody tr td{
                &:last-child{
                  text-align: right;
                }
            }
        }
        &.candidature-tab{
            & img{
                width: 15px;
            }
            & .card{
                background-color:$white;
                box-shadow: none;
                border: none;
                & .card-body{
                    padding:0px;
                    & .dropzone{
                        min-height: 50px;
                        border: 2px solid #b3b3b3;
                        & .dz-message{
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}
.add-course-modal{
    & .dropzone{
        width: 36px;
        min-height: auto;
        border: none;
        & .dz-message{
            padding: 0px;
        }
        & img{
            width:20px;
        }
    }
}
.add-skills-modal, .courses-tab{
    
    & .top-industry-container{
        margin-top: 50px;
        & .industry{
            padding: 10px;
            border: 1px solid #eeee;
        }
    }
    & .skills-learns{
        padding-left: 0px;

        & .skills-items{
            background-color:#74B496;
            padding: 2px 5px;
            border-radius: 12px;
            color:$white;
            font-weight: 100;
            & span {
                font-size: 33px;
                position: relative;
                top: -2px;
                color: #C4C4C4;
            }
        }
    }
}
.add-skills-modal .form-control input:focus{
    border-color:#ededed;
    height: auto;
}
.select-container .css-yk16xz-control{
    border-color:#ededed;
    &:focus{
        border-color:#ededed;
    }
    &:hover{
        border-color:#ededed;
    }
}
.select-container .css-1pahdxg-control,
.select-container .css-1pahdxg-control:hover{
    box-shadow: none;
    border-color:#ededed;
}
.select-container .css-tlfecz-indicatorContainer{
    color: transparent;
}
.select-container .css-1hb7zxy-IndicatorsContainer{
    display: none;
}
.select-container .css-xb97g8:hover{
    background-color:#e6e6e6;
    color:$dark-green;
    cursor: pointer;
}
.proficiency-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    & img{
        cursor: pointer;
        width: 20px;
    }
    & .text-transform{
        text-transform: capitalize;
    }
   
}
.skill-container {
    margin: 5px 10px;
    padding: 5px 10px;
    border-radius: 45px;
    border: 1px solid #e5e5e5;
    position: relative;
    color: #74B496;
    background-color: #ffffff;
    font-weight: 300;
    font-size: 0.75rem;
}

// .skill-container{
//     min-width: 80px;
//     padding: 5px 10px;
//     border-radius: 45px;
//     border: 1px solid #C4C4C4;
//     position: relative;
//     color:#447E63;
//     & img{
//         width: 20px;
//     }
//     & .skill-pro{
//         width: 7px;
//         height: 7px;
//         border-radius: 100%;
//         background-color: #eeee;
//         color:transparent;
//         float:left;
//         margin-left: 5px;
//         margin-top: 8px;
//         &.selected{
//             background-color: #6B6B6B;
//         }
//     }
// }
.edit-bio-modal textarea{
    height: 100px;
}
.courses-tab, .certification-tab{
    .btn-outline-shadow{
        background-color: $white;
    }
}
.certification-tab{
    .dropzone{
        & img{
            width:20px;
        }
    }
}
.add-education .select-container .css-1hb7zxy-IndicatorsContainer,
.emp-modal .select-container .css-1hb7zxy-IndicatorsContainer{ display: block;}

.add-education .select-container .css-tlfecz-indicatorContainer,
.emp-modal .select-container .css-tlfecz-indicatorContainer{
    color: hsl(0,0%,80%);
}
.profile-page .card{
    background-color: $white;
    box-shadow: none;
    border: 1px solid #eeee;
}
.profile-page{ 
    padding-left: 45px;
    padding-right: 45px;
}
.profile-page{
    .top-container{
        background-color: white;
        border-radius: 4px;
        .ux-designer{
            color: white;
            padding-top: 2.5%;
            padding-left: 8%;
            background: linear-gradient(270.31deg, #98D9BB 0.51%, rgba(255, 255, 255, 0) 68.45%), #447E63;
            height: 70px;
            font-size: 20px;
            border-top-left-radius:4px;
            border-top-right-radius:4px;
        }
        .shift-image{
            margin-top: -30px;
        }
        .company-info{
            height: 60px;
            margin-top: -2.5%;
            margin-left: 2%;
        }
        .green-status-text{
            padding: 5px 10px;
            background-color: $light-green;
            color:$white;
            font-size: 11px;
            font-weight: 200;
            border-radius: 15px;
        }
    }

    .right-pencil-section{
        position: absolute;
        padding: 5px;
        border: 1px solid #eeee;
        top: -13px;
        right: 15px;
        background: #fff;
        border-radius: 100%;
        & .bx-pencil{
            position: relative;
            top: 2px;
            right: -1px;
        }
    }
    .about-section{
        background-color: $white;
        margin-top: 25px;
        padding: 20px;
    }
    .activity-container{
        cursor: pointer;
        .card-header{
            background-color: $white;
            border-radius: 4px;
            border: none;
            border: 1px solid #f6f6f6;
            border-bottom: none;
            box-shadow: none;
        }
        .description{ 
            padding: 10px 0px;
            border-top: 1px solid #eeee;
        }
        .collapse-shadow{
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
        // .verti-timeline{
        //     border-left : 2px solid #696D6D;
        // }
    }
}

//Edit Profile Modal

.edit-profile-modal{

    .upload-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & .upload-link{
            text-decoration-line: underline;
        }
    }
    .dropzone{
        width: auto;
        min-height: auto;
        border: none;
        padding-left: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        & .dz-message{
            padding :0px;
            font-size: 16px;
            width:auto;
            text-align: left;
        }   
        img{
            width: 15px;
        }
    }
    .profile-logo-container{
        border-radius: 100%;
        padding: 5px;
        & img{
            background-color: $white;
        }
    }
    .company-size{
        .selected{
            box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.18);
        }
    }
    .green-status-text{
        padding: 5px 15px;
        background-color: $light-green;
        color:$white;
        font-size: 11px;
        font-weight: 200;
        border-radius: 40px;
        text-align: center;
        margin-right: 10px;
    }
}

.college-profile{
    .right-pencil-section{
        top: -88px;
    }
}