.box-personal {
  background: #ffffff;
  min-height: 130px;
  border: 1px solid #eeeeee;
  min-width: 335px;
}
.btn-sp {
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
}
.top-border-box-g {
  border: #dddddd;
}

.bg-verification-modal .modal-body {
  padding-right: 55px;
  padding-left: 55px;
  .bg-uplaod-btn {
    padding: 10px;
    & img {
      width: 14px;
    }
  }
  .pf-margin {
    margin-top: 21px;
  }
  .company-logo-img {
    width: 55px;
    position: relative;
    img {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    }
    .img-close {
      cursor: pointer;
      width: 15px;
      height: 15px;
      background: #eb5757;
      border-radius: 100%;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      position: absolute;
      right: 8px;
      top: -5px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
}
