$dark-green: #213d30;
$light-green: #74b496;
$card-background-color: #f3f3f3;
$profile-border-color: #dde1df;
$light-text-color: #6f6f6f;
$text-black: #000000;
$border-bottom-color: #eeeeee;
$btn-grey-background: #c4c4c4;
$light-bg-color: #d5e8e0;

.label-required {
    color: #f46a6a;
}

// .form-group label{
//     color:$light-text-color;
// }
// .form-group input,.form-group select{
//     border: none;
//     border-radius: 0px;
//     border-bottom: 1px solid #E0E0E0;
//     padding: 0px 0px;
//     height: auto;
// }
@font-face {
    font-family: "Helvetica";
    font-weight: normal;
    font-style: normal;
    src: url("../../assets/fonts/Helvetica.ttf") format("truetype");
}

body {
    font-family: "Helvetica";
}

.text-color-light {
    color: $light-text-color;
}

.form-group input::placeholder {
    color: $light-text-color;
    font-weight: 300;
}

.font-weight-200 {
    font-weight: 200;
}

.noti-icon .badge {
    left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.rdw-editor-main {
    border: 1px solid #eeeef5;
    height: 239px;
}

.fcYNFc {
    background-color: #ffffff !important;
}

.task-box {
    border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.fc-event .fc-content {
    padding: 5px;
    color: #fff;
}

.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event {
    &:hover {
        cursor: pointer;
    }
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #7cb342 !important;
}

//button background color
.bg-green {
    background-color: #213d30;
    color: #fff;

    &:hover {
        color: #fff;
    }

    &:focus {
        box-shadow: none;
    }
}

.bg-grey {
    background-color: $btn-grey-background;
    color: $text-black;

    &:hover {
        color: $text-black;
    }

    &:disabled {
        opacity: 0.3;
    }

    &:focus {
        box-shadow: none;
    }
}

.btn-outline-shadow {
    color: #213d30;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #fff;

    &:hover {
        color: #213d30;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    &:focus {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    }
}

.btn-outline-green {
    color: #213d30;
    border-color: #213d30;

    &:hover {
        color: #fff;
        background-color: #213d30;
        border-color: #213d30;
    }

    &:focus {
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.1;
    }
}

.bg-light-green {
    background-color: #74b496;
    color: #fff;

    &:hover {
        color: #fff;
    }

    &:focus {
        box-shadow: none;
    }
}

.btn-outline-grey {
    color: $text-black;
    border-color: #4f4f4f;

    &:focus {
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.1;
    }
}

//text color
.green-text-color {
    color: #213d30;

    &:hover {
        color: #213d30;
    }
}

.light-green-text {
    color: $light-green;

    &:hover {
        color: $light-green;
    }
}

.light-green-text-color {
    color: #447e63;

    &:hover {
        color: #447e63;
    }
}

//Date Css
.cust-date-icon {
    position: absolute;
    right: 5px;
    top: 4px;
    font-size: 18px;
    cursor: pointer;
}

.react-datepicker__input-container input::placeholder {
    color: #e0e0e0;
}

//Toast Notification
#toast-container > .toast-error {
    background-image: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

///Body

body[data-layout-size="boxed"] {
    background-color: #f6f6f6;
    color: $dark-green;
}

//page-content padding

body[data-layout="horizontal"] {
    .page-content {
        padding-top: #{$grid-gutter-width};
        min-height: 100vh;
        // background-color: $white;
    }
}

//top header
body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
    background-color: $dark-green;
}

#page-topbar {
    box-shadow: none;
}

.app-search {
    width: 55%;
    margin-left: 20%;
}

body[data-layout="horizontal"][data-topbar="colored"] .app-search .form-control {
    // border-radius: 2px;
    border: none;
    background-color: #fff;
    color: #495057;
    padding-left: 15px;
    padding-right: 40px;
    height: 30px;
}

.app-search span {
    left: unset;
    right: 13px;
    line-height: 30px;
}

body[data-layout="horizontal"][data-topbar="light"] a.header-item {
    &:hover,
    &.active {
        color: $dark-green;
        border-bottom: 3px solid $dark-green;
        // font-weight: bold;
        // text-shadow: 0 0 0.65px $dark-green, 0 0 0.65px $dark-green;
        padding-bottom: 22px;
    }
}

body[data-layout="horizontal"][data-topbar="light"] .app-search span,
body[data-layout="horizontal"][data-topbar="light"] .app-search input.form-control::-webkit-input-placeholder {
    color: #74788d;
}

.navbar-header .logo.logo-dark {
    img {
        width: 160px;
    }
}

// React Table Css
.table.react-table {
    & tbody {
        background-color: $card-background-color;
    }

    & th,
    td {
        border: none;
    }

    & td {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
        vertical-align: middle;
    }
}

// link underline

.sign-in-link {
    color: $dark-green;
    border-bottom: 1px solid $dark-green;

    &:hover {
        color: $dark-green;
        border-bottom: 1px solid $dark-green;
    }
}

.text-under-line {
    text-decoration-line: underline;
}

body[data-layout-size="boxed"] #layout-wrapper {
    box-shadow: none;
}

.twitter-bs-wizard-tab-content {
    min-height: 160px;
}

.dropdown-menu {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px 0px 4px 4px;

    a {
        color: $light-green;
    }
}

.select-container .css-1hb7zxy-IndicatorsContainer,
.select-container .css-1hb7zxy-IndicatorsContainer {
    display: block;
}

.select-container .css-tlfecz-indicatorContainer,
.select-container .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%);
}

.select-container.multiple-select .css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer,
.select-container.multiple-select .css-1hb7zxy-IndicatorsContainer .css-1gtu0rj-indicatorContainer {
    display: none;
}

.select-container.multiple-select .css-1rhbuit-multiValue {
    background-color: $light-green;
    border-radius: 12px;
    color: $white;

    .css-12jo7m5 {
        color: $white;
    }
}

.select-container.multiple-select .css-xb97g8:hover {
    background-color: transparent;
    color: #ffffff;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.3;
}

.job-form .year-text {
    position: absolute;
    right: 11%;
    top: 55%;
}

.job-form,
.job-dashbord,
.application-tab {
    .skill-container {
        min-width: 45px;
        padding: 0px 5px;
        border-radius: 45px;
        position: relative;
        color: $white;
        background-color: #74b496;
        font-weight: 300;
        font-size: 14px;
    }
}

.detail-tab {
    .skill-container {
        padding: 5px 10px;
        border-radius: 45px;
        position: relative;
        color: $white;
        background-color: #74b496;
        font-weight: 300;
        font-size: 14px;
    }
}

.cut-text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* React Tag Styles Start */

div.ReactTags__tags {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 4px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    position: static;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
    display: inline-block;
    border: none !important;
}

div.ReactTags__tagInput:focus {
    border: none !important;
    outline: none;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    // border: 1px solid #eee;
    padding: 0 4px;
    border: none !important;
    border-color: transparent;
    outline: none;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    color: $white;
    background-color: #74b496;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 2px 5px;
    cursor: move;
    border-radius: 20px;
}

div.ReactTags__selected a.ReactTags__remove {
    color: $white;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
    z-index: 1;
    width: 100%;
    overflow: visible;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    max-height: 300px;
    overflow: auto;
    letter-spacing: normal;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e5e5ee;
    padding-left: 0px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    font-family: inherit;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    margin-left: 0px;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
    margin-left: 0px;
}

/* React Tag Styles End */
.font-weight-100 {
    font-weight: 100;
}

.blue-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #4d4af2;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

.green-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #174731;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

.orange-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #f2994a;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

.red-status-text {
    font-size: 11px;
    font-weight: 300;
    background-color: #eb5757;
    color: $white;
    border-radius: 20px;
    padding: 3px 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.info-timeline li:not(:first-of-type) div.list-dot::before {
    position: absolute;
    border: 1px solid #999;
    width: 0;
    height: 50px;
    display: block;
    content: "";
    left: 50%;
    z-index: 1;
    top: -54px;
    margin-left: -1px;
}

.accordin-open-container .verti-timeline {
    border-left: 3px solid $dark-green;
}

.list-dot {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    background: $dark-green;
    border-radius: 100%;
    // position: relative;
    // border: 2px solid #999;
    // border-radius: 100%;
    // width: 50px;
    // line-height: 50px;
    // text-align: center;
    // margin-top: 50px;
    // background-color: #fff;
    // z-index: 2;
    // display: inline-block;
}

.row-expand {
    background-color: #ffffff;
    padding: 2em;
    border: 1px solid #e5e5e5;
    border-top: none;
    border-radius: 4px;
}

.collapse-shadow {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.circle_plus {
    border: 1px solid #213d30;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    text-align: center;
}

.hiding_div {
    position: absolute;
    color: #fff;
    width: 2%;
    height: 70%;
    top: 15px;
    left: -8px;
    bottom: 0;
    z-index: 1;
    background: #fff;
}

.hiding_div_top {
    position: absolute;
    color: #fff;
    width: 2%;
    height: 70%;
    top: 0;
    left: -8px;
    bottom: 0;
    z-index: 1;
    background: #fff;
}

.vertial_divider {
    margin-left: 10px;
    margin-right: 15px;
    color: #e5e5e5;
    height: 100%;
}

.main-content {
    .verti-timeline {
        .event-list {
            .event-timeline-dot {
                top: 5px;
            }
        }
    }
}

.upload {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.profile-page .avatar-md .img-thumbnail {
    height: 70px;
    width: 200px;
}

.udemy_icon {
    width: 20px;
}

.site_icon_wrapper {
    position: absolute;
    right: 0%;
    top: 32%;
}

@media (min-width: 1600px) {
    .job-details .top-container .ux-designer {
        padding-top: 2% !important;
        padding-left: 6.3% !important;
    }

    .job-details .top-container .company-info {
        margin-top: -1.8% !important;
    }
}

.font-size-27 {
    font-size: 27px;
}

.btn-primary {
    background-color: $dark-green;
    border-color: $dark-green;
}

.navbar-header .input-group .input-group-append {
    right: 0px;
    top: 0px;
}

.mobileDevice {
    .navbar-header {
        padding-left: 12px;
        padding-right: 0;

        .logo.logo-dark img {
            width: 110px;
        }

        .navbar-brand-box {
            padding: 0;
        }

        .header-font .btn {
            padding: 0.47rem 0.5rem;
        }
    }

    .header_down_arrow {
        font-size: 1.5em;
    }

    .profile-page {
        padding-right: 12px;
        padding-left: 12px;
    }

    .web_display_flex {
        display: block;
    }

    .web_display {
        display: block;
    }

    .mobile_margin_5 {
        margin-top: 2em;
    }

    .job-details .top-container .green-status-text {
        padding: 3px 5px;
    }

    .mob_display {
        display: flex;
    }

    .account-pages {
        .right-section {
            display: none;
        }

        .left-section {
            width: 100%;
            padding: 20px 0px;
        }

        .login-heading {
            margin-left: 0;
        }

        .login-form {
            margin-left: 0;
        }

        // .form_wrapper{
        //     padding-left: 0 !important;
        // }
        .mobile_left_padding {
            padding-left: 1rem !important;
        }
    }

    .account-setting {
        margin-top: 90px !important;

        .mobile_padding {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }

    .mobile_job_header {
        margin-bottom: 1rem !important;
        padding-right: 0 !important;
    }

    .accordin-open-container .verti-timeline {
        border-left: none;
    }

    .twitter-bs-wizard .twitter-bs-wizard-nav .nav-item .nav-link .step-number::before {
        left: 59%;
        width: 85%;
    }

    .mobile_margin {
        margin-top: 2em;
    }

    .mobile_bottom_padd {
        padding-bottom: 3px;
    }

    .box-personal {
        min-width: 100%;
    }

    .bg-verification-modal .modal-body {
        padding: 1rem;
    }

    .bg-verification-modal .bg_verfication_top_mobile {
        margin-top: 1rem !important;
    }
}

body[data-layout="horizontal"].mobileDevice .page-content {
    padding-top: 80px;
}

.top-border-box-g {
    border: #dddddd;
    padding: 20px;
    background: #447e633d;
    border-radius: 4px;
}

@media (max-width: 380px) {
    .navbar-brand-box {
        display: block;
    }

    .mobileDevice .mobile_fonts {
        font-size: 0.7em !important;
    }

    .w-lg {
        min-width: 120px;
    }
}

.web_display_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.web_display {
    display: flex;
}

.notification_container {
    .dropdown-menu a {
        &:hover {
            color: #74b496;
        }
    }

    .btn:focus {
        box-shadow: none;
    }
}

.toast-top-right .toast-success {
    border: 2px solid #34c38f !important;
    background-color: #34c38f !important;
}

input[type="checkbox"] {
    visibility: hidden;
}

.main {
    margin-left: 0.5rem;
}

.geekmark {
    position: absolute;
    top: 8px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #fff;
    border: 2px solid #eeeeee;
    border-radius: 4px;
}

.main input:checked ~ .geekmark {
    background-color: $light-green;
    border: none;
}

.main input:checked ~ .geekmark:after {
    display: block;
}

.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

.main .geekmark:after {
    left: 8px;
    bottom: 6px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(52deg);
}

.text_light_green {
    color: #34c38f;
}

.text_light_green:hover {
    color: #248763;
}

.font-size-36 {
    font-size: 36px !important;
}

.custom_skill_level {
    background-color: $white;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 0px;
    padding: 4px 8px;
    border-radius: 15px;
    color: #74b496;
    font-weight: 100;
    border-color: $white;
}

.custom_skill_level_highlight {
    background-color: #74b496;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 0px;
    padding: 4px 8px;
    border-radius: 15px;
    color: $white;
    font-weight: 100;
    border-color: #74b496;
}

.apply_resume_wrapper {
    .company-logo-img {
        width: 60px;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        position: relative;

        img {
            width: 40px;
            height: 40px;
        }

        .img-close {
            cursor: pointer;
            width: 15px;
            height: 15px;
            background: #eb5757;
            border-radius: 100%;
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            position: absolute;
            right: -8px;
            top: -5px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }
}

.candidature {
    & .dropzone {
        width: 36px;
        min-height: auto;
        border: none;

        & .dz-message {
            padding: 0px;
        }

        & img {
            width: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    body .job-details .top-container .ux-designer {
        height: auto;
    }
}
